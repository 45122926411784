import React, { useState } from "react"
import ContextConsumer from "./context"

const Episode = ({ id, name, description, index, spotifyurl }) => {
  const [expand, setExpand] = useState(false)

  const expandDescription = e => {
    console.log("Clicked div!")
    e.preventDefault()
    e.persist()
    e.nativeEvent.stopImmediatePropagation()
    e.cancelBubble = true
    e.stopPropagation()
    setExpand(!expand)
  }

  const loadEpisode = (e, set, id) => {
    console.log("Clicked button!")
    e.preventDefault()
    e.persist()
    e.nativeEvent.stopImmediatePropagation()
    e.cancelBubble = true
    e.stopPropagation()
    set({ player: id })
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <div
          className="episode-container br4 justify mb3 pa3 w-90 w-100-ns"
          onClick={event => expandDescription(event)}
          onKeyDown={event => expandDescription(event)}
          role="button"
          tabIndex={index}
        >
          <h3 className="cursor-text">{name}</h3>
          <p className="cursor-text tj">
            {expand ? description : description.slice(0, 200).trim() + "..."}
          </p>
          <button
            className="ttu br4 pt1 pr3 pl3 pb1 mt3"
            onClick={event => loadEpisode(event, set, id)}
          >
            play
          </button>
        </div>
      )}
    </ContextConsumer>
  )
}

export default Episode
