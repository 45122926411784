import React, { useEffect, useState } from "react"
import Episode from "../components/episode"

const Home = () => {
  let [episodes, setEpisodes] = useState([])

  useEffect(() => {
    const getAuthorizationToken = async () => {

      const base64string = window.btoa(process.env.GATSBY_CLIENT_ID + ":" + process.env.GATSBY_CLIENT_SECRET)

      const response = await fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Basic ${base64string}`,
        },
        body: "grant_type=client_credentials",
      }).then(response => response.json())

      return `Bearer ${response.access_token}`
    }

    const fetchEpisodes = async () => {
      const result = await fetch(
        `https://api.spotify.com/v1/shows/68tt1i43PKfXqJ3SVL6Gjf?market=SV`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": await getAuthorizationToken(),
          },
        }
      )

      // console.log('result', await result)

      const resultData = await result.json()
      // console.log('resultData', resultData)
      let episodes = resultData.episodes.items.map(episode => {
        return {
          id: episode.id,
          audio_preview_url: episode.audio_preview_url,
          description: episode.description,
          duration_ms: episode.duration_ms,
          spotify_url: episode.external_urls.spotify,
          spotify_uri: episode.uri,
          images: episode.images,
          release_date: episode.release_date,
          name: episode.name,
        }
      })

      setEpisodes(episodes)
      return episodes
    }

    fetchEpisodes()
  }, [])

  return (
    <div className="flex flex-column items-center justify-start">
      <div className="tc pt4 pt0-ns tl-ns">
        <h2>Episodes</h2>
      </div>
      {episodes.map((e, index) => (
        <Episode
          name={e.name}
          id={e.id}
          description={e.description}
          index={index}
          key={e.id}
          spotifyurl={e.spotify_url}
        />
      ))}
    </div>
  )
}

export default Home
